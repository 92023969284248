
@import "~@fontsource/nunito/200.css";
@import "~@fontsource/nunito/300.css";
@import "~@fontsource/nunito/400.css";
@import "~@fontsource/nunito/500.css";
@import "~@fontsource/nunito/600.css";
@import "~@fontsource/nunito/700.css";
@import "~@fontsource/nunito/800.css";
@import "~@fontsource/nunito/900.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  letter-spacing: 0.005em;

  @supports (letter-spacing: 0.005cap) {
    letter-spacing: 0.005cap;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
    2. Remove default margin
  */
* {
  margin: 0;
}

/*
    3. Allow percentage-based heights in the application
  */
html,
body {
  height: 100%;
}
/*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
body {
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
* {
  line-height: calc(1em + 0.2rem);
}

/*
    6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
    7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
  font: inherit;
}
/*
    8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
    9. Create a root stacking context
  */
#root,
#__next {
  isolation: isolate;
}

.react-tel-input .selected-flag:hover {
  background-color: #e6e3e3 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Tiny scrollbar for Chrome, Safari and Opera */
.tiny-scrollbar::-webkit-scrollbar {
  width: 0.3em;
  opacity: 0;
}
.tiny-scrollbar {
  scrollbar-width: 0.3em; /* Firefox */
}
.tiny-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0;
}
.tiny-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(211, 207, 207);
  border-radius: 1em;
}

@media only screen and (max-width: 600px) {
  .phone_input_dropdown_input {
    width: unset !important;
    padding-right: 20px !important;
  }

  .phone_input_dropdown_container {
    width: 100%;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messageview::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.messageview {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  transition: 2s ease-in-out;
}

.swipe {
  position: absolute;
  max-height: 700px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;
}

.cardContainer {
  max-width: 100%;
  display: flex;
  height: 760px;
  flex-direction: column;
  align-items: center;
}

.card {
  position: relative;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.slick-dots li button:before {
  color: #d9d9d9 !important;
  margin-top: 20px;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: #ffffff !important;
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 5em;
  height: 5em;
  border: 1.1em solid rgba(128, 0, 128, 0.616);
  border-left: 1.1em solid #0000005e;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.webcam {
  width: 100%;
  height: 100%;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
